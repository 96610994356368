<template>
  <div class="order-info-container">
    <div class="card">
      <div class="card-line">
        <cell-v3 :hideIcon="true">
          <div class="main-title" slot="title">
            取货点：{{ orderInfo.pickInfo.store_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hideIcon="true">
          <div class="title" slot="title">订单编码：</div>
          <div class="values">{{ orderInfo.pickInfo.flow_up_code }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hideIcon="true">
          <div class="title" slot="title">联 系 人 ：</div>
          <div class="values">{{ orderInfo.pickInfo.lianxiren }}</div>
        </cell-v3>
      </div>
      <div class="card-line bottom-border">
        <cell-v3 :hideIcon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">{{ orderInfo.pickInfo.mobile }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hideIcon="true">
          <div class="main-title" slot="title">
            配送点：{{ orderInfo.pointsList.site_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hideIcon="true">
          <div class="title" slot="title">负 责 人 ：</div>
          <div class="values">{{ orderInfo.pointsList.site_leader }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hideIcon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">{{ orderInfo.pointsList.sp_mobile }}</div>
        </cell-v3>
      </div>

      <div class="goods-list">
        <div
          class="goods"
          v-for="(item, index) in orderInfo.goodList"
          :key="index"
        >
          <goods-v4
            :img-url="item.cover"
            :number="item.number"
            :title="item.title"
          />
        </div>
      </div>

      <cell-v3>
        <div class="money">获得佣金：+{{ orderInfo.commission }}元</div>
      </cell-v3>
    </div>
  </div>
</template>

<script>
import CellV3 from "../../template/community/cell/cellV3";
import GoodsV4 from "../../template/community/goods/GoodsV4";

export default {
  name: "order-info",
  components: { GoodsV4, CellV3 },
  data() {
    return {
      doId: 0,
      orderInfo: [],
    };
  },
  created() {
    if (typeof this.$route.query.doId != "undefined") {
      this.doId = parseInt(this.$route.query.doId);
    }
    this.getOrderInfo();
  },
  methods: {
    async getOrderInfo() {
      try {
        let query = null;
        query = await this.$api.community.courier.geDoneOrderInfo({
          id: this.doId,
          keyword: this.keyword,
        });
        this.orderInfo = query.data;
      } catch (error) {
        this.$toast.fail(error.message);
      }
    },
    searchOrder() {
      this.getOrderInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.order-info-container {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 15px 0;

  .card {
    background: #fff;
    margin: 15px;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    .main-title {
      white-space: nowrap;
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .title,
    .values {
      color: #333333;
      font-size: 14px;
    }

    .card-line {
      margin-bottom: 9px;
    }

    .bottom-border {
      padding: 0 0 15px 0;
      border-bottom: #ebebeb solid 1px;
      margin-bottom: 20px;
    }

    .goods-list {
      width: 100%;
      border-top: #ebebeb solid 1px;
      border-bottom: #ebebeb solid 1px;
      padding-bottom: 15px;
      margin-top: 15px;

      .goods {
        margin-top: 15px;
      }
    }

    .money {
      white-space: nowrap;
      margin-top: 15px;
      font-size: 16px;
      color: #c10000;
      font-weight: bold;
    }
  }
}
</style>
